import React from "react";
import { faKey, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import apiService from "../../services/apiKeyService";

type ApiKey = {
  id: number;
  name: string;
  condensedName: string;
  apiKeyType: string;
};

export type Organization = {
  id: number;
  apiKeys: ApiKey[];
  organizationTypeId: number;
};

type ApiKeyButtonProps = {
  showModal: Function;
  closeModal: Function;
  organization: Organization;
  onSaved: Function;
};

export const OrgTypes = {
  Organization: 1,
  ParentOrganization: 2,
  ChildOrganization: 3,
  Steris: 4,
} as const;

export const ApiKeyUserRolesEnum = {
  //🧙‍♂️ keys from the database
  CasechekApiClient: {
    id: 30200,
    name: "Casechek Api Client",
    condensedName: "casechekApiClient",
  },
  TorqApiClient: {
    id: 30300,
    name: "Torq Api Client",
    condensedName: "torqApiClient",
  },
  SpmApiClient: {
    id: 30000,
    name: "Spm Api Client",
    condensedName: "spmApiClient",
  },
  DynamicConfigApiClient: {
    id: 30100,
    name: "Dynamic Config Api Client",
    condensedName: "dynamicConfigApiClient",
  },
  SynergyTrakApiClient: {
    id: 31000,
    name: "SynergyTrak Api Client",
    condensedName: "synergyTrakApiClient",
  },
  AcmewareApiClient: {
    id: 30400,
    name: "Acmeware Api Client",
    condensedName: "acmewareApiClient",
  },
} as const;


export const getOrgKeyTypesToShow = (organization: Organization) => {
  const orgType = organization.organizationTypeId;
  if (orgType === OrgTypes.ParentOrganization || !organization.apiKeys) {
    return [];
  }
  //sort the api keys by name
  var allApiKeys = Object.values(ApiKeyUserRolesEnum).sort((a, b) => a.name.localeCompare(b.name));
  
  return allApiKeys.filter(a => !organization.apiKeys.some(b => b.apiKeyType.toUpperCase() === a.name.toUpperCase()));
};


export const ApiKeyButton = ({
  showModal,
  closeModal,
  organization,
  onSaved,
}: ApiKeyButtonProps) => {
  const orgKeyTypesToShow = getOrgKeyTypesToShow(organization);
  const { t } = useTranslation();

  const handleCreateApiKey = async (keyTypeToAdd: any) => {
    var apiKey = { organizationId: organization.id, userRole: keyTypeToAdd };
    const response = await apiService.createApiKey(apiKey);
    if (response.data.success) {
      var result = response.data.payload;
      onSaved(result);
      closeModal();
    }
  };

  return (
    <span
      className="fa-layers fa-fw text-success float-right show-hand"
      onClick={() => {
        showModal(
          {
            open: true,
            focus: true,
            title: t("Create New Api Key"),
            message: t(
              "Please select the api key type you wish to create for this customer."
            ),
            model: orgKeyTypesToShow,
            closeModal: closeModal,
            saveModel: handleCreateApiKey,
          },
          "createApiKeyModal"
        );
      }}
    >
      <FontAwesomeIcon icon={faKey} transform={{ rotate: -45 }} />
      <FontAwesomeIcon icon={faPlus} transform="shrink-8 right-10" />
    </span>
  );
};
